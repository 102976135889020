import React, { useState, useEffect } from 'react';
import { Container, List, ListItem, ListItemText, Typography, Paper, Button, CircularProgress } from '@mui/material';

const RequestLogs = () => {
    const [logs, setLogs] = useState([]);
    const [visibleLogs, setVisibleLogs] = useState(10);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchLogs();
    }, []);

    const fetchLogs = () => {
        setLoading(true);
        fetch('https://supply.hitarget.ai/api/logs')
            .then(response => response.json())
            .then(data => {
                setLogs(data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching logs:', error);
                setLoading(false);
            });
    };

    const loadMoreLogs = () => {
        setVisibleLogs(prevVisibleLogs => prevVisibleLogs + 10);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Request Logs</Typography>
            <List>
                {logs.slice(0, visibleLogs).map((log, index) => (
                    <ListItem key={index} component={Paper} style={{ marginBottom: '10px', padding: '10px' }}>
                        <ListItemText>
                            <Typography variant="subtitle2"><strong>ID:</strong> {log.id}</Typography>
                            <Typography variant="subtitle2"><strong>Timestamp:</strong> {new Date(log.timestamp).toLocaleString()}</Typography>
                            <Typography variant="subtitle2"><strong>Bid Request:</strong></Typography>
                            <pre style={{ fontSize: '12px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(JSON.parse(log.bidRequest), null, 2)}</pre>
                            <Typography variant="subtitle2"><strong>Bid Response:</strong></Typography>
                            <pre style={{ fontSize: '12px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(JSON.parse(log.bidResponse), null, 2)}</pre>
                            {log.winningBid && (
                                <>
                                    <Typography variant="subtitle2"><strong>Winning Bid:</strong></Typography>
                                    <pre style={{ fontSize: '12px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(JSON.parse(log.winningBid), null, 2)}</pre>
                                    <Typography variant="subtitle2"><strong>Winning Price:</strong> {log.winningPrice}</Typography>
                                </>
                            )}
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
            {visibleLogs < logs.length && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button variant="contained" color="primary" onClick={loadMoreLogs} disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Load More'}
                    </Button>
                </div>
            )}
        </Container>
    );
};

export default RequestLogs;
