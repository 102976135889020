import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container, TextField, Button, List, ListItem, ListItemText, Typography, Paper } from '@mui/material';

const DSPEndpoints = () => {
    const [endpoints, setEndpoints] = useState([]);
    const [newEndpoint, setNewEndpoint] = useState('');

    useEffect(() => {
        fetch('https://supply.hitarget.ai/api/dsp')
            .then(response => response.json())
            .then(data => setEndpoints(data));
    }, []);

    const addEndpoint = () => {
        const uniqueId = uuidv4();
        const newDspEndpoint = {
            url: newEndpoint,
            uniqueId: uniqueId,
            impressions: 0,
            clicks: 0,
            budgetSpent: 0
        };
        fetch('https://supply.hitarget.ai/api/dsp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newDspEndpoint)
        })
        .then(response => response.text())
        .then(message => {
            setEndpoints([...endpoints, newDspEndpoint]);
            setNewEndpoint('');
        });
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Add DSP Endpoints</Typography>
            <TextField
                fullWidth
                label="Enter DSP endpoint"
                value={newEndpoint}
                onChange={(e) => setNewEndpoint(e.target.value)}
                variant="outlined"
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={addEndpoint} style={{ marginTop: '16px' }}>Add Endpoint</Button>
            <List style={{ marginTop: '16px' }}>
                {endpoints.map((endpoint, index) => (
                    <ListItem key={index} component={Paper} style={{ marginBottom: '10px', padding: '10px' }}>
                        <ListItemText
                            primary={`URL: ${endpoint.url}`}
                            secondary={
                                <>
                                    <Typography component="p">Unique ID: {endpoint.uniqueId}</Typography>
                                    <Typography component="p">Impressions: {endpoint.impressions}</Typography>
                                    <Typography component="p">Clicks: {endpoint.clicks}</Typography>
                                    <Typography component="p">Budget Spent: ${endpoint.budgetSpent.toFixed(2)}</Typography>
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default DSPEndpoints;
