import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container, TextField, Button, List, ListItem, ListItemText, Typography, Paper, Grid } from '@mui/material';

const PublisherIntegration = () => {
    const [publishers, setPublishers] = useState([]);
    const [newPublisher, setNewPublisher] = useState({ name: '', adSlot: '', url: '' });
    const [generatedCode, setGeneratedCode] = useState('');

    useEffect(() => {
        fetch('https://supply.hitarget.ai/api/publishers')
            .then(response => response.json())
            .then(data => setPublishers(data))
            .catch(error => console.error('Error fetching publishers:', error));
    }, []);

    const addPublisher = () => {
        fetch('https://supply.hitarget.ai/api/publishers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newPublisher)
        })
        .then(response => response.text())
        .then(message => {
            setPublishers([...publishers, newPublisher]);
            setNewPublisher({ name: '', adSlot: '', url: '' });
        })
        .catch(error => console.error('Error adding publisher:', error));
    };

    const generateCode = (publisher) => {
        const requestId = uuidv4();
        const code = `
            <script>
            document.addEventListener('DOMContentLoaded', function() {
                var adSlot = document.getElementById("${publisher.adSlot}");
                if (adSlot) {
                    fetch('https://supply.hitarget.ai/bidder/v2_5/bid', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            id: '${requestId}',
                            imp: [{
                                id: '1',
                                banner: {
                                    w: 300,
                                    h: 250
                                }
                            }],
                            site: {
                                id: '${publisher.name}',
                                domain: window.location.hostname
                            },
                            device: {
                                ua: navigator.userAgent,
                                ip: '192.168.1.1',
                                geo: {
                                    lat: 37.7749,
                                    lon: -122.4194
                                },
                                dnt: 0,
                                lmt: 0
                            },
                            user: {
                                id: 'user-id',
                                buyeruid: 'buyer-uid'
                            }
                        })
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.seatbid && data.seatbid.length > 0) {
                            const adMarkup = data.seatbid[0].bid[0].adm;
                            adSlot.innerHTML = adMarkup;

                            adSlot.querySelector('iframe').style.width = "${publisher.adSlotWidth}px";
                            adSlot.querySelector('iframe').style.height = "${publisher.adSlotHeight}px";
                             adSlot.querySelector('iframe').style.overflow = "hidden";  // Добавляем атрибут overflow: hidden
                            adSlot.addEventListener('click', function() {
                                var clickImg = new Image();
                                clickImg.src = data.seatbid[0].bid[0].nurl; // Use nurl for click tracking
                                clickImg.style.display = 'none';
                                document.body.appendChild(clickImg);
                            });
                        } else {
                            adSlot.innerHTML = '<p>No ads available</p>';
                        }
                    })
                    .catch(error => console.error('Error fetching bid response:', error));
                }
            });
            </script>
        `;

        setGeneratedCode(code);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Add Publishers</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Publisher Name"
                        value={newPublisher.name}
                        onChange={(e) => setNewPublisher({ ...newPublisher, name: e.target.value })}
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Ad Slot ID"
                        value={newPublisher.adSlot}
                        onChange={(e) => setNewPublisher({ ...newPublisher, adSlot: e.target.value })}
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Publisher URL"
                        value={newPublisher.url}
                        onChange={(e) => setNewPublisher({ ...newPublisher, url: e.target.value })}
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" onClick={addPublisher} style={{ marginTop: '16px' }}>Add Publisher</Button>
            <List style={{ marginTop: '16px' }}>
                {publishers.map((publisher, index) => (
                    <ListItem key={index} component={Paper} style={{ marginBottom: '10px', padding: '10px' }}>
                        <ListItemText
                            primary={`${publisher.name} - ${publisher.adSlot} - ${publisher.url}`}
                            secondary={
                                <Button variant="contained" color="secondary" onClick={() => generateCode(publisher)}>Generate Code</Button>
                            }
                        />
                    </ListItem>
                ))}
            </List>
            {generatedCode && (
                <div>
                    <Typography variant="h5" gutterBottom>Generated Code:</Typography>
                    <Paper style={{ padding: '10px', marginTop: '10px' }}>
                        <pre>{generatedCode}</pre>
                    </Paper>
                </div>
            )}
        </Container>
    );
};

export default PublisherIntegration;
