import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, List, ListItem, ListItemText } from '@mui/material';
import DSPEndpoints from './components/DSP/DSPEndpoints';
import PublisherIntegration from './components/Publishers/PublisherIntegration';
import RequestLogs from './components/Logs/RequestLogs';

function App() {
    return (
        <Router>
            <div className="App">
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" style={{ flexGrow: 1 }}>SSP UI</Typography>
                        <List component="nav" style={{ display: 'flex', flexDirection: 'row' }}>
                            <ListItem button component={Link} to="/dsp">
                                <ListItemText primary="DSP Endpoints" />
                            </ListItem>
                            <ListItem button component={Link} to="/publishers">
                                <ListItemText primary="Publishers" />
                            </ListItem>
                            <ListItem button component={Link} to="/logs">
                                <ListItemText primary="Request Logs" />
                            </ListItem>
                        </List>
                    </Toolbar>
                </AppBar>
                <Container>
                    <Routes>
                        <Route path="/dsp" element={<DSPEndpoints />} />
                        <Route path="/publishers" element={<PublisherIntegration />} />
                        <Route path="/logs" element={<RequestLogs />} />
                        <Route path="/" element={<Typography variant="h4" style={{ marginTop: '20px' }}>Welcome to the SSP UI</Typography>} />
                    </Routes>
                </Container>
            </div>
        </Router>
    );
}

export default App;
